import React from 'react';
import cn from 'classnames';

import style from './FormErrorMessage.scss';

const FormErrorMessage = ({ children, className }) => {
  return (
    <p className={cn(style.error, className)}>
      {children}
    </p>
  );
};

export default FormErrorMessage;
