import React from 'react';

import cn from 'classnames';

import CheckmarkIcon from '../../../assets/images/CheckMarkBlack.svg';
import ToolTip from '../Tooltip';

import style from './Checkbox.module.scss';

export interface ICheckboxInputProps {
  name: string;
  value: string;
  onChange: (name: string, value: boolean | string | number) => void;
  type?: 'checkbox' | 'radio';
  isChecked: boolean;
  content: string;
  className?: string;
  contentClassName?: string;
  tickClassName?: string;
  isDisabled?: boolean;
  toolTip?: string;
}

const Checkbox = (props: ICheckboxInputProps) => {
  const {
    name = '',
    value,
    type = 'checkbox',
    content,
    // hasError,
    onChange,
    className,
    contentClassName,
    tickClassName,
    isDisabled = false,
    isChecked = false,
    toolTip,
    // exclude from restProps
    ...restProps
  } = props;

  const cssClasses = cn(style.checkbox, className, {
    [style.radio]: props.type === 'radio',
    [style.checked]: props.value,
    // [style.error]: props.hasError,
    [style.disabled]: isDisabled,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (typeof onChange === 'function' && !isDisabled) {
      onChange(name, type === 'checkbox' ? !isChecked : value);
    }
  };

  return (
    <label className={cssClasses}>
      <input
        type={type}
        name={name}
        onChange={handleChange}
        value={value}
        checked={isChecked}
        {...restProps}
      />
      <span className={cn(tickClassName, style.tick)}>
        <img src={CheckmarkIcon} alt="" />
      </span>
      {content ? (
        <span
          className={cn(contentClassName, style.content)}
          dangerouslySetInnerHTML={{ __html: content }}
        ></span>
      ) : null}
      {toolTip ? <ToolTip toolTipText={toolTip} /> : null}
      {/* <HelperText className={style.errorMessage}>{helperText}</HelperText> */}
    </label>
  );
};

export default React.memo<ICheckboxInputProps>(Checkbox);
