import React from 'react';

import DownPayment from '@trendcapital/loan-calculators-widget/src/components/Input/DownPayment/Compact';
import Input from '@trendcapital/loan-calculators-widget/src/components/Input/Input';
import TextInput from '../Input';
import FormErrorMessage from '../FormErrorMessage';
import Select from '@trendcapital/loan-calculators-widget/src/components/Input/Select';
import LoanLimitsTable from '@trendcapital/loan-calculators-widget/src/components/LoanLimits/LoanLimitsTable';
import { LoanTermSelect } from '@trendcapital/loan-calculators-widget/src/components/Input/LoanTerm/LoanTermSelect';
import { stateOptions } from '@trendcapital/loan-calculators-widget/src/utils/calculations';
import cn from 'classnames';
import { calculatorTabs, defaultCalculatorsList } from '../../constants/calculators';
import { defaultSettings } from '../../constants/settings';

import { withFirebase } from '../../utils/Firebase';
import Button from '../Buttons/PrimaryButton';
import Checkbox from '../Input/Checkbox';
import Loading from '../Loading';

import style from './CalculatorSettings.scss';

const CalculatorSettings = ({ firebase, authUser }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [settingsLoading, setSettingsLoading] = React.useState(true);
  const [values, setValues] = React.useState(defaultSettings);

  const [VAEntitlementCountyLimits, setVAEntitlementCountyLimits] = React.useState([]);
  const [FHACountyLimits, setFHACountyLimits] = React.useState([]);

  const [error, setError] = React.useState();
  const [domainValidationError, setDomainValidationError] = React.useState();

  React.useEffect(() => {
    fetch('https://wmplimits.s3-us-west-2.amazonaws.com/FhaLoanLimits.json', {
      method: 'GET',
    })
      .then((res) => res.text())
      .then((result) => {
        setFHACountyLimits(JSON.parse(result));
      })
      .catch((err) => {
        console.log('error', err);
      });
  }, []);

  React.useEffect(() => {
    fetch('https://wmplimits.s3-us-west-2.amazonaws.com/ConventionalLimits.json', {
      method: 'GET',
    })
      .then((res) => res.text())
      .then((result) => {
        const limits = JSON.parse(result);
        setVAEntitlementCountyLimits(limits);
      })
      .catch((err) => {
        console.log('error', err);
      });
  }, []);

  const limitsStateOptions = VAEntitlementCountyLimits.reduce((acc, item, currentIndex, array) => {
    // if next item is same as this item, continue
    if (array[currentIndex + 1]?.stateAbbreviation === item.stateAbbreviation) {
      return acc;
    }
    return [
      ...acc,
      {
        label: item.state
          .toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' '),
        value: item.stateAbbreviation,
      },
    ];
  }, []);

  const countyOptions = VAEntitlementCountyLimits.reduce((acc, item) => {
    return item.stateAbbreviation === values.ratesState
      ? [
          ...acc,
          {
            label: item.county
              .toLowerCase()
              .split(' ')
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(' '),
            value: item.county,
          },
        ]
      : acc;
  }, []);

  const VAEntitlementCountyLimit = VAEntitlementCountyLimits.find(
    (entry) =>
      entry.stateAbbreviation === values.ratesState && entry.county === values.loanLimitsCounty
  ) || {
    county: '',
    fourUnit: 0,
    oneUnit: 0,
    threeUnit: 0,
    twoUnit: 0,
  };

  const FHACountyLimit = FHACountyLimits.find(
    (entry) =>
      entry.stateAbbreviation === values.ratesState && entry.county === values.loanLimitsCounty
  ) || {
    county: '',
    fourUnit: 0,
    oneUnit: 0,
    threeUnit: 0,
    twoUnit: 0,
  };

  const getSettings = async () => {
    const docRef = await firebase.firestore.collection('calculator_settings').doc(authUser.uid);

    docRef.onSnapshot((snap) => {
      if (snap.data()) {
        // console.log('Document data:', snap.data());
        const loadedValues = snap.data();
        if (!loadedValues.calculatorsList) {
          loadedValues.calculatorsList = defaultCalculatorsList;
        }
        setValues(loadedValues);
      } else {
        console.log('error', 'There is no saved settings');

        // load default settings to the database
        firebase.firestore.collection('calculator_settings').doc(authUser.uid).set(values);
      }
      setSettingsLoading(false);
    });
  };

  React.useEffect(() => {
    if (firebase) {
      getSettings();
    }
  }, [firebase]);

  const handleInputChange = (name, value) => {
    // console.log(name);

    switch (name) {
      case 'propertyTaxRate':
        setValues({
          ...values,
          [name]: value,
          defaultPropertyTaxRate: value,
          propertyTax: Math.round(values.homePrice * (+value / 100)),
        });
        break;
      case 'homeInsuranceRate':
        setValues({
          ...values,
          [name]: value,
          defaultHomeInsuranceRate: value,
          homeInsurance: Math.round(values.homePrice * (+value / 100)),
        });
        break;
      case 'ratesState':
        const state = stateOptions.find((f) => f.value === value).label.toUpperCase();
        setValues({
          ...values,
          [name]: value,
          state: state,
        });
        break;
      case 'loanLimitsCounty':
        setValues({
          ...values,
          [name]: value,
          locationCounty: value,
        });
        break;
      case 'downPaymentPercent':
        setValues({
          ...values,
          [name]: value,
          downPaymentAmount: Math.round(values.homePrice * (+value / 100)),
        });
        break;
      default:
        setValues({
          ...values,
          [name]: value,
        });
        break;
    }
  };

  const handleCalculatorsListChange = (name, value) => {
    setValues({
      ...values,
      calculatorsList: { ...values.calculatorsList, [name]: value },
    });
  };

  const handleDomainChange = (event) => {
    if (!isValidDomain(event.target.value)) {
      setDomainValidationError(new Error('Please enter a valid domain'));
    } else {
      setDomainValidationError(undefined);
    }

    setValues({
      ...values,
      domain: event.target.value,
    });
  };

  const isValidDomain = (value) => {
    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,63}$/;

    return domainRegex.test(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidDomain(values.domain)) {
      setDomainValidationError(new Error('Please enter a valid domain'));
      return;
    }

    setIsLoading(true);

    firebase.firestore
      .collection('calculator_settings')
      .doc(authUser.uid)
      .set(values)
      .then(() => {
        setError(undefined);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  };

  const onBlur = () => {};
  return (
    <>
      <div className={cn('row', style.calculatorSettings)}>
        <div className="col-12">
          <h1>Calculator settings</h1>
        </div>
        <>
          <div className="col-12">
            {!settingsLoading ? (
              <>
                <form onSubmit={handleSubmit}>
                  <h2>Base settings</h2>
                  <div className={style.inputs}>
                    <Input
                      name="interestRateAnnual"
                      label="Interest Rate"
                      min={0}
                      max={12}
                      step={0.125}
                      maskOptions={{ decimalLimit: 3 }}
                      value={values?.interestRateAnnual}
                      onChange={handleInputChange}
                      termPhrase="%"
                      hasPrefix={false}
                    />
                    <Input
                      name="homePrice"
                      label="What’s your home price?"
                      value={values?.homePrice}
                      onChange={handleInputChange}
                      // error={errorFields.homePrice}
                      onBlur={onBlur}
                      termPhrase=""
                      isDynamicStep
                    />
                    <DownPayment
                      label="Down Payment"
                      downPaymentAmount={(values.homePrice * values.downPaymentPercent) / 100}
                      downPaymentPercent={values.downPaymentPercent}
                      onChange={handleInputChange}
                    />
                    {/* <Input
                      name="loanTerm"
                      value={values?.loanTerm}
                      labelName="Loan term"
                      onChange={handleInputChange}
                    /> */}
                    <LoanTermSelect value={values.loanTerm} onChange={handleInputChange} />
                    <Input
                      name="propertyTaxRate"
                      label="Annual Property Tax"
                      value={values.propertyTaxRate}
                      onChange={handleInputChange}
                      prefixSymbol=""
                      min={0}
                      max={4}
                      step={0.1}
                      termPhrase={`$${values.propertyTax}`}
                      styling={'widgetStyling'}
                      maskOptions={{ suffix: '%' }}
                    />
                    <Input
                      name="homeInsuranceRate"
                      label="Annual Home Insurance"
                      value={values.homeInsuranceRate}
                      onChange={handleInputChange}
                      termPhrase={`$${values.homeInsurance}`}
                      prefixSymbol=""
                      min={0}
                      step={0.1}
                      max={1.5}
                      styling={'widgetStyling'}
                      maskOptions={{ suffix: '%' }}
                    />
                    <Select
                      label="State"
                      name="ratesState"
                      options={limitsStateOptions}
                      value={values.ratesState}
                      placeholder=""
                      onChange={handleInputChange}
                    />
                    <Select
                      label="County"
                      name="loanLimitsCounty"
                      options={countyOptions}
                      value={values?.loanLimitsCounty}
                      placeholder=""
                      onChange={handleInputChange}
                    />
                    {/* TODO: Check if it is required */}
                    <Input
                      name="zipCode"
                      label="Zip code"
                      value={values?.zipCode}
                      onChange={handleInputChange}
                      hasRange={false}
                      prefixSymbol={''}
                      termPhrase={''}
                      maskOptions={{ includeThousandsSeparator: false, allowDecimal: false }}
                    />
                  </div>
                  <div className={style.loanLimitsLookupTitle}>
                    Conforming & FHA Loan Limits{' '}
                    {FHACountyLimit.county &&
                      'for ' +
                        values?.loanLimitsCounty
                          .toLowerCase()
                          .split(' ')
                          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                          .join(' ') +
                        ', ' +
                        values?.ratesState}
                  </div>
                  <LoanLimitsTable
                    FHACountyLimit={FHACountyLimit}
                    VAEntitlementCountyLimit={VAEntitlementCountyLimit}
                  />

                  <h2>Calculators list</h2>
                  <div className={style.inputs}>
                    {calculatorTabs.map((tab) => {
                      const key = Object.keys(tab)[0];
                      const title = tab[key];

                      return (
                        <Checkbox
                          key={key}
                          name={key}
                          value={values?.calculatorsList[key]}
                          isChecked={!!values?.calculatorsList[key]}
                          content={title}
                          onChange={handleCalculatorsListChange}
                        />
                      );
                    })}
                  </div>
                  <h2>Domain settings</h2>
                  <p>You can only use the widget on the selected domain</p>
                  <TextInput
                    name="domain"
                    label="Domain"
                    value={values.domain ?? ''}
                    onChange={handleDomainChange}
                    labelName="Domain"
                  />
                  {domainValidationError && (
                    <FormErrorMessage>{domainValidationError.message}</FormErrorMessage>
                  )}
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  <div>
                    <Button
                      type="submit"
                      disabled={!!domainValidationError}
                      data-id={authUser.id}
                      isLoading={isLoading}
                    >
                      Save calculator settings
                    </Button>
                  </div>
                </form>
              </>
            ) : (
              <div className={style.loadingContainer}>
                <Loading />
              </div>
            )}
          </div>
          <div className={cn('col-12', style.widgetCodeWrapper)}>
            <h1>Add widget to your site</h1>
          </div>
          <div className="col-12">
            {/* <div className={style.copyToClipboard}>
              <button className={style.copyButton}> // onClick={this.copyToClipboard}>
                Copied
              </button>
            </div> */}
            <textarea
              readOnly
              style={{
                width: '100%',
                fontSize: '14px',
                padding: '16px',
                height: '160px',
                padding: '12px 20px',
                boxSizing: 'border-box',
                border: '2px solid #ccc',
                borderRadius: '4px',
                backgroundColor: '#f8f8f8',
                fontSize: '16px',
                resize: 'none',
                fontFamily: 'monospace',
              }}
              defaultValue={`<script
  async
  type="text/javascript"
  src="https://tools.whatsmypayment.com/widgetCode.js"
  data-settings="${authUser.uid}"
  id="whats-my-payment-widget"
></script>`}
            />
            <p>
              To install our mortgage calculator widget, please copy the script tag above and simply
              paste the line of code on your website where you want your calculator to appear.
            </p>
          </div>
        </>
      </div>
    </>
  );
};

export default withFirebase(CalculatorSettings);
