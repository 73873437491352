import React from 'react';

// import Tooltip from '../../../assets/images/Tooltip.svg';

import style from './ToolTip.module.scss';

const Label = React.memo((props: { toolTipText: string }) => {
  const { toolTipText } = props;

  return (
    <div className={style.tooltip} data-text={toolTipText}>
      {/* <img src={Tooltip} /> */}
    </div>
  );
});

export default Label;
